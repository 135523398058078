import React from 'react';
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
  <>
    <Helmet>
        <meta charset="utf-8" />
        <title>Take A Look At The Privacy Policies For The 2.0 Conferences</title>
        <base href="/" />
        <meta name="robots" content="index"></meta>
        <meta name="description" content=" Review The 2.0 Conferences' privacy policy detailing how we collect, use, and protect your personal information across our events and website. " />
        <meta property="og:title" content=" Take A Look At The Privacy Policies For The 2.0 Conferences" />
        <meta property="og:description" content="Review The 2.0 Conferences' privacy policy detailing how we collect, use, and protect your personal information across our events and website." />

    </Helmet>
        <section className="home_banner " style={{paddingBottom: '16px'}}>
            <figure className="home_figure">
                <img src="https://the2conf.com/the2conf-Privacy-Policy.jpg" alt="Privacy-Policy" width="100%" />
                <figcaption className="home_figure_caption">
                    <div className="container">
                        <div className="banner_text">
                            <h1>Privacy Policy </h1>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </section>
        <section className="policy_services exhibit_enquire_banner across_sectors_banner gallery_banner_caption">
            <div className="container" style={{fontSize : '16px' }}>
                <div className="content">
                    <div className="wrapper-1">
                        <div className="wrapper-2" style={{marginTop : '16px' }}>
                        <p>
                        <strong>Last revised on 10/01/2025</strong>
                        </p>
                        <p>
                        This privacy policy aims to provide clarity regarding the information we collect and explain how your Personally Identifiable Information (PII) is used online. PII refers to details that can be used alone or combined with other data to identify, contact, or locate an individual or recognize someone in a specific context. We encourage you to review this policy carefully to understand how we gather, use, safeguard, and manage your PII when you engage with our website.
                        </p>
                        
                        <p>
                        <strong>What personal information do we collect from the people that visit our blog, website, or app?</strong>
                        </p>
                        <p>We collect limited personal information, such as your name, address, phone number, and email address. Additionally, we may gather your IP location to ensure you are connected with the appropriate representative for follow-up.</p>
                        <p>
                        <strong>When do we collect information?</strong> 
                        </p>
                        <p>Information is collected when you fill out forms, subscribe to our blog, engage with the Live Chat feature, or submit details through other sections of our website.</p>
                        <p>
                        <strong>How do we use your information?</strong>
                        </p>
                        <p>
                        This information may be used to:
                        </p>
                        <ul style={{listStyleType: 'disc'}}>
                            <li>
                            Personalize your experience by presenting content and offers tailored to your preferences.</li>
                            <li>Improve our website's performance and overall user experience.</li>
                            <li>Respond more effectively to customer service requests.</li>
                            <li>Request feedback through ratings and reviews for our services.</li>
                            <li>Follow up on previous interactions, including emails, live chats, and phone conversations.</li>
                            
                        </ul>

                        <p>
                        <strong>How do we share the information we collect?
                        </strong></p>
                        <p>We do not disclose your information to third parties, except for our partners, sponsors, and exhibitors.
                        </p>
                        <p>
                        Your credit or debit card details will never be stored, shared, sold, leased, or rented to any third parties under any circumstances.
                        </p>
                        <p>
                        <strong>How do we protect your information?</strong></p>
                        
                        <p>We do not perform vulnerability scanning or assessments based on PCI standards. However, we conduct regular malware scans to help protect our systems. Your personal information is safeguarded within secure networks, accessible only to a limited group of authorized personnel who have special access rights and are obligated to keep the information confidential. Additionally, any sensitive or payment-related data you provide is encrypted using Secure Socket Layer (SSL) technology.

                        </p>
                        <p>
                        To help protect your personal information, we implement multiple security measures when you enter, submit, or access your data. All financial transactions are securely processed through a third-party gateway provider and are neither stored nor handled on our servers.
                        </p>
                        <p>While we strive to apply robust security measures, no method of data protection can be entirely foolproof. We encourage you to take precautions when sharing personal information online, such as using firewalls, antivirus software, anti-spyware tools, and other protective technologies to safeguard your data.</p>
                        
                        <p>
                        <strong>Cookie policy:</strong>
                        </p>
                        <p>
                        This website uses cookies to distinguish you from other users of the website. This helps us to provide you with a good experience when you browse our website and allows us to improve the website.
                        </p>
                        <p><strong>Information About Cookies:</strong></p>
                        <p>
                        Cookies are small data files stored on your computer's hard drive when you allow a website or service provider to send them through your web browser. They help websites identify your browser and remember certain details to improve user experience.
                        </p>
                        <p>
                        <strong>Do We Use Cookies?</strong>
                        </p>

                        <p>
                        Yes, we use cookies to understand your preferences based on your interactions with our site, helping us deliver a more personalized experience. Cookies also assist in collecting aggregated data about site traffic and visitor behavior, allowing us to refine our website's performance and improve the services we provide.
                        </p>
                        <p><strong>For how long do we store the cookies?</strong></p>
                        <p>
                        We retain cookies for a period of 30 days. Information collected for marketing purposes will be stored until you inform us that you no longer wish to receive such communications. For further details on our data retention practices, please refer to our retention schedule available online.
                        </p>
                        <p><strong>We use cookies to:</strong></p>
                        <p>
                        We use cookies to store your preferences for future visits, helping create a more tailored browsing experience. Cookies also assist in gathering summarized data on site traffic and user interactions, enabling us to continually improve our website’s features and overall performance.</p>

                        <p>You can control how cookies are managed on your device. You may choose to receive a notification each time a cookie is sent or disable all cookies through your browser settings. Since browser settings can vary, refer to your browser's Help section for guidance on adjusting cookie preferences. </p>
                        <p>
                        <strong>Turning off Cookies:</strong>
                        </p>
                        <p>
                        If you turn cookies off, some of the features that make your site experience more efficient may not function properly.
                        </p>
                        <p>
                        <strong>Third-party disclosure</strong>
                        </p>
                        <p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information.</p>
                        <p>
                        <strong>Third-party links</strong></p>


                            <p>At times, we may include links to third-party products or services on our website. These external sites operate under their own privacy policies, independent of ours. Therefore, we do not assume responsibility for their content, privacy practices, or activities. However, we are committed to maintaining a trustworthy platform and welcome any feedback regarding your interactions with these third-party links.
                        </p>
                        <p>
                        <strong>Google</strong>
                        </p>
                        <p>Google’s advertising requirements can be summed up by Google’s Advertising Principles. They are put in place to provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en
                        </p>
                        <p>
                        <strong>We Use Google AdSense Advertising On Our Website</strong>
                        </p>
                        <p>Google, as an independent third-party service provider, uses cookies to display ads on our site. The DART cookie helps Google deliver ads based on users' past visits to our site and other websites across the internet. Users can choose to disable the DART cookie by visiting the Google Ad and Content Network's privacy policy.
                        </p>
                        <p>
                        <strong>We have implemented the following:</strong>
                        </p>
                        <p>
                        <strong>Remarketing with Google AdSense</strong>
                        </p>
                        <p>
                        <strong>Google Display Network Impression Reporting</strong>
                        </p>
                        <p>
                        We, along with third-party vendors such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions and other ad services functions as they relate to our website.
                        </p>
                        <p>
                        <strong>HubSpot:</strong>
                        </p>
                        <p>We collaborate with HubSpot to streamline and manage our marketing communications. HubSpot uses cookies to track your interactions with our website, including browser-related details such as your IP address and visit frequency. Based on this data, HubSpot may estimate your general geographic location. Your identity remains anonymous to HubSpot unless you choose to provide personal details through actions like inquiring about our services, submitting job applications, or responding to forms and links we share, as HubSpot assists us in managing these interactions. To learn more about HubSpot’s data practices and cookie usage, please review their privacy policy here: <a href="https://legal.hubspot.com/privacy-policy" target="_blank" rel="nofollow noreferrer">https://legal.hubspot.com/privacy-policy</a>. 
                        </p>
                        <p>
                        <strong>Facebook:</strong><p>

                        </p>
                         When you visit our website, a direct connection between your browser and Facebook’s servers may be established through a plug-in. This connection allows Facebook to receive information that you have visited our site, including your IP address. If you click the Facebook “Like” button while logged into your account, the content from our pages can be linked to your Facebook profile, enabling Facebook to associate your visit with your account. As the website provider, we do not have access to the data transmitted or insight into how Facebook processes it. For more details, please refer to Facebook's Data Privacy Policy at http://de-de.facebook.com/policy.php. If you prefer not to have your visit linked to your Facebook account, please log out of your Facebook profile before interacting with our site.
                        </p>
                        <p>
                        <strong>LinkedIn:</strong></p>
                        <p>Our website uses functions of the network LinkedIn. The provider is LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA. For each visit to one of our pages containing functions of LinkedIn, a connection to the LinkedIn servers is established. LinkedIn is notified that you visited our websites with your IP address. If you click on the “Recommend button” of LinkedIn and are logged into your account with LinkedIn, LinkedIn is able to associate your visit to our website to you and your user account. We would like to point out that in our capacity as provider of the website, we are not aware of the contents of the transmitted data or how it is processed by LinkedIn. For more information, please see the Data Privacy Statement of LinkedIn under https://www.linkedin.com/legal/privacy-policy.

                        </p>
                        <p>
                        <strong>Opting out:</strong></p>
                        <p>Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can choose to opt out by visiting the Network Advertising Initiative Opt Out page or by using the Google Analytics Opt Out Browser add-on.
                        </p>
                        <p>
                        <strong>California Online Privacy Protection Act</strong>
                        </p>
                        <p>
                        The California Online Privacy Protection Act (CalOPPA) was the first state law to mandate that commercial websites and online services display a privacy policy. Its scope extends beyond California, requiring any individual or business in the US (and potentially worldwide) that collects Personally Identifiable Information (PII) from California residents to publish a clearly visible privacy policy. This policy must outline the types of information collected and disclose how and with whom it may be shared. For more details, visit "
                        <a href="http://consumercal.org/california-online-privacy-protection-act-caloppa/" target= "_blank" rel="nofollow noreferrer" >http://consumercal.org/california-online-privacy-protection-act-caloppa/</a>".
                    
                        </p>
                        <p>
                        Users have the option to browse our website without revealing their identity.</p>

                        <p>Once this privacy policy is finalized, a link to it will be added on our homepage or, at a minimum, on the first significant page visitors access upon entering the site.

                        </p>
                        <p>
                        The link to our Privacy Policy will be clearly labeled as "Privacy" and easily accessible from the specified location.
                        </p>
                        <p>
                        <strong>Notification Of Privacy Policy Updates:</strong>
                        </p>
                        <p> Any updates or changes to our Privacy Policy will be communicated directly on the Privacy Policy page.
                        </p>
                        <p>
                        <strong>Updating Your Personal Information:</strong>
                        </p>
                        <p> You can update or modify your personal information by:
                        </p>
                        
                        <ul style={{listStyleType : 'disc'}}>

                            <li>Sending us an email</li>
                            <li>Contacting us by phone</li>
                            <li>Engaging with our support team via live chat or submitting a support ticket.
                            </li>
                        </ul>



                        <p>
                        <strong>How does our site handle Do Not Track signals?</strong></p>
                        <p> We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.
                        </p>
                        <p>
                        <strong>Does our site allow third-party behavioral tracking?</strong></p>
                        
                        <p> It’s also important to note that we allow third-party behavioral tracking.
                        </p>
                        <p>
                        <strong>Fair Information Practices</strong>
                        </p>
                        <p>
                        The Fair Information Practice Principles are key foundations of U.S. privacy regulations and have influenced global data protection laws. Implementing these principles correctly is vital for ensuring compliance with various laws that safeguard personal data.
                        </p>
                        <p>If a data breach occurs, we will take the following actions to align with privacy best practices:</p>
                        <p>Notify affected users by email within 7 business days of the breach being confirmed.</p>
                        <p>We recognize the importance of individual rights to seek action against data handlers who violate privacy laws. Individuals are entitled to pursue legal channels against non-compliant data processors through courts or regulatory authorities.</p>

                        <p>Our Privacy Policy and Terms & Conditions may be updated periodically to reflect evolving legal requirements and standards. We encourage users to review these sections regularly, as any updates will take effect from the date they are published on the website.
                        </p>
                        
                    </div>
                    </div>
                </div>
            </div>
        </section>
    </>
   
   
   
   
  )
}

export default PrivacyPolicy