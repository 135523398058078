import React from "react";
import { NavLink } from "react-router-dom";
import { AiFillFacebook } from 'react-icons/ai';
import { RiTwitterXFill } from 'react-icons/ri';
import { AiFillInstagram } from 'react-icons/ai';
import { AiFillLinkedin } from 'react-icons/ai';
import { AiFillYoutube } from 'react-icons/ai';
import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import { GrLocation } from 'react-icons/gr';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section className="footer_section">
      <div className="container">
        <div className="primery_footer">
         <div className="row">
          <div className="col-12 col-md-12 col-lg-4">
          <div className="footer_logo">
          <a className="secondary_logo" href="/">
            <img src="https://s3.amazonaws.com/health-conference/the2conf/the2-conf-color-logo.svg" alt="logo" />
          </a>
          <ul className=" social_media">
            <li> <a href="https://www.twitter.com/the2conf/" target="_blank" rel="noopener noreferrer"><RiTwitterXFill/></a></li>
            <li><a href="https://www.facebook.com/the2conf/" target="_blank" rel="noopener noreferrer"><AiFillFacebook/></a></li>
            <li> <a href="https://www.instagram.com/the2conf/" target="_blank" rel="noopener noreferrer"><AiFillInstagram/></a></li>
            <li> <a href="https://www.linkedin.com/company/the2conf/" target="_blank" rel="noopener noreferrer"><AiFillLinkedin/></a></li>
            <li><a href="https://www.youtube.com/@the2conf" target="_blank" rel="noopener noreferrer"><AiFillYoutube/></a> </li>
          </ul>
          </div>
          </div>
          <div className="col-12 col-md-12 col-lg-8">
         <div className="row">
         <div className="col-12 col-md-4 col-lg-4">
         <ul className="social_list_scam social_list">
            <h5>Company</h5>
          
           <li><a href="/our-gallery" className="nav-link"> Our Gallery</a></li>
           <li><a href="/sectors" className="nav-link">Sectors</a></li>
          </ul>
         </div>
         <div className="col-12 col-md-4 col-lg-4">
        
         <ul className="social_list_scam social_list">
           <h5>Useful Links</h5>
            <li><NavLink to="/event-scam" className="nav-link" rel="noopener noreferrer">Event Scams </NavLink></li>
            <li><NavLink to="/privacy-policy" className="nav-link" rel="noopener noreferrer">Privacy Policy </NavLink></li>
            <li><NavLink to="/terms-of-services" className="nav-link" rel="noopener noreferrer">Terms Of Service </NavLink></li>
            {/* <li><NavLink to="/blogs" className="nav-link">Blogs </NavLink></li> */}
          </ul>
         </div>
         <div className="col-12 col-md-4 col-lg-4">
         <ul className="social_list_scam social_list">
            <h5>Reach Us</h5>
            <li>
            <address className="the2conf_address"><span><GrLocation/></span> 5940 S Rainbow Blvd<br/>
                      Ste 400PMB 47489,<br/>
	                    Las Vegas, Nevada 89118-2507 (USA)
                     </address>
            </li>
          </ul>
         </div>
         </div>

         </div>
         </div>
      
         
         
             
         
          
        </div>
        <div className="secondary_footer">
          <div className="row">
         
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <p className="condition_list text-center">
               
                <strong>©</strong> <a href="/" rel="noopener noreferrer"> <strong> The 2.0 Conferences, {(new Date().getFullYear())} | All Rights Reserved.  </strong></a>
                
              </p>
            </div>
            
          </div>
      
        </div>
        <span className="back_to_top">
        <NavLink
                onClick={scrollToTop}
                role="button"
                className="back_top nav-link"
      
              >
                <BsFillArrowUpCircleFill/>
              </NavLink>
        </span>
      </div>
    </section>
  );
};

export default Footer;
