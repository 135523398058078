import React from 'react'

const ThankYou = () => {
  return (
    <>
      <section className='thank-you'>
        <div className="container">
          <div className="content">
            <div className="wrapper-1">
              <div className="wrapper-2">
                <h1>Thank you !</h1>
                <p>
                  And... it's a home run! Your submission has just powered up
                  our inbox. Stay tuned — we'll be in touch within the next
                  24-48 hours. Exciting things await!{" "}
                </p>

                <button className="go-home">
                  <a href="/" className="home_btn">
                    go home
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ThankYou
